import React from 'react'
import { Navigation } from './components/Navigation/Navigation'
import { AdComp } from './components/Adsense/Adsense'

function App() {
  return (
    <>
      <Navigation />
      <AdComp/>
    </>
  )
}

export default App
